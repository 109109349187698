import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useQuery } from '../hooks/useQuery';
import Social from '../components/social';
import Layout from '../components/layout-exOr';
import { Helmet } from 'react-helmet';

//import style from "../components/layout.scss"

function Page({ pageContext }) {
  const data = useQuery();
  const page = pageContext;
  const meta = {
    title: page.frontmatter.title || '',
    description: page.frontmatter.description || page.frontmatter.excerpt || '',
    imgLocation: `${data.site.siteMetadata.siteUrl}` || '',
    socialImg: page.frontmatter.featuredSocial
      ? page.frontmatter.featuredSocial?.childImageSharp.original.src
      : page.frontmatter.featuredImage?.childImageSharp.original.src,
    socialTitle: page.frontmatter.socialTitle
      ? page.frontmatter.socialTitle
      : page.frontmatter.title,
    socialImgWidth: page.frontmatter.featuredSocial
      ? page.frontmatter.featuredSocial?.childImageSharp.original.width
      : page.frontmatter.featuredMobile?.childImageSharp.original.width,
    socialImgHeight: page.frontmatter.featuredSocial
      ? page.frontmatter.featuredSocial?.childImageSharp.original.height
      : page.frontmatter.featuredMobile?.childImageSharp.original.height,
    socialImgType: page.frontmatter.featuredSocial
      ? page.frontmatter.featuredSocial?.internal.mediaType
      : page.frontmatter.featuredMobile?.internal.mediaType,
  };
  const styles = {
    bannerColor: page.frontmatter.bannerColor,
    headingsColor: page.frontmatter.headingsColor,
    headingsFont: page.frontmatter.headingsFont,
    h2FontSize: page.frontmatter.h2FontSize,
    h3FontSize: page.frontmatter.h3FontSize,
    h4FontSize: page.frontmatter.h4FontSize,
    imgBorderColor: page.frontmatter.imgBorderColor,
    imgBorderWidth: page.frontmatter.imgBorderWidth,
  };
  const image = getImage(page.frontmatter.featuredImage);
  const mobile = getImage(page.frontmatter.featuredMobile);
  const facebookurl = `${data.site.siteMetadata.siteUrl}/${page.frontmatter.slug}`;
  const twitterurl = `${data.site.siteMetadata.siteUrl}/${
    page.frontmatter.slug
  }/&text=${meta.socialTitle.replace(/ /g, '+')}`;

  // console.log(page);

  return (
    <Layout meta={meta} styles={styles}>
      {page.frontmatter.language ? (
        <Helmet
          bodyAttributes={{
            id: `${page.frontmatter.slug}-page`,
            lang: `${page.frontmatter.language}`,
          }}
        />
      ) : (
        <Helmet bodyAttributes={{ id: `${page.frontmatter.slug}-page` }} />
      )}
      <div id={`${page.frontmatter.slug}`}>
        <header>
          <GatsbyImage
            image={image}
            loading="eager"
            alt={page.frontmatter.featuredAlt}
            className={`headerImage ${
              page.frontmatter.featuredMobile ? 'desktopImage' : ''
            }`}
          />
          {page.frontmatter.featuredMobile ? (
            <GatsbyImage
              image={mobile}
              loading="eager"
              alt={page.frontmatter.featuredAlt}
              className="headerImage mobileImage"
            />
          ) : (
            ''
          )}
          <div className={`headerText screen-reader-text`}>
            <h1>{page.frontmatter.title}</h1>
          </div>
        </header>
        <main className="main">
          <MDXRenderer>{page.body}</MDXRenderer>
          {page.frontmatter.share ? (
            <Social facebookurl={facebookurl} twitterurl={twitterurl} />
          ) : (
            ''
          )}
        </main>
      </div>
    </Layout>
  );
}

export default Page;
