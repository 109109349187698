import * as React from 'react';
import './footer.scss';

const Footer = ({ menuLinks }) => {
  return (
    <>
      <section id="pre-footer">
        <div class="small-12 large-9 columns body-copy single-content">
          <img src="https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2020/07/credits.png" alt="In association with Virgil Films a Billy Graham Evangelistic Association film, Billy Graham: An Extraordinary Journey." />
        </div>
      </section>
    {/* <footer className="new-footer">
      <section className="widget_text widget custom_html-3 widget_custom_html">
        <div className="textwidget custom-html-widget">
          <div className="columns">
            <div className="column logo-column">
              <a href="https://billygraham.org" target="_blank" rel="noreferrer">
                <img
                  src={BGEALogo}
                  alt="Billy Graham Evangelistic Association"
                  width="141"
                  height="60"
                />
              </a>
            </div>
            <div className="column">
              <p>
                <a href="https://billygraham.org/" target="_blank" rel="noreferrer">
                  A ministry of The Billy Graham Evangelistic Association
                </a>
                <br />© {new Date().getFullYear()} | BGEA is a registered
                501(c)(3) non-profit organization. |{' '}
                <a
                  href="https://billygraham.org/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </footer> */}
    </>
  );
};

export default Footer;
